<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'AudienceList',
              }"
              >受眾管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active
              >{{
                isReadOnly ? "查看受眾" : isEdit ? "編輯受眾" : "新增受眾"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看受眾" : isEdit ? "編輯受眾" : "新增受眾"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="受眾名稱"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.audience.title.$error ? false : null"
                    v-model="audience.title"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.audience.title.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="受眾描述"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="audience.description"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="受眾來源"
                >
                  <b-form-radio-group
                    v-model="audience.source"
                    :options="options"
                  ></b-form-radio-group>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="上傳"
                  v-if="audience.source == 'import'"
                >
                  <b-dropdown text="上傳檔案">
                    <b-dropdown-item @click="audience.source_type = 'line'" v-b-modal.modal-upload>LINE UID 匯入</b-dropdown-item>
                    <b-dropdown-item @click="audience.source_type = 'phone'" v-b-modal.modal-upload>手機匯入</b-dropdown-item>
                    <b-dropdown-item @click="audience.source_type = 'code'" v-b-modal.modal-upload>會員編號</b-dropdown-item>
                  </b-dropdown> 
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="受眾條件"
                  v-if="audience.source == 'filter'"
                >
                </b-form-group>

                <div v-if="audience.source == 'filter'">
                  <div v-for="(cs, idx) in audience.conditionSet.conditions" :key="idx">
                    <b-form-select
                      v-if="idx != 0"
                      :options="[
                        { text: '而且', value: 'AND' },
                        { text: '或', value: 'OR' },
                      ]"
                      :value="null"
                      v-model="audience.conditionSet.matched"
                      class="w-auto my-3"
                    ></b-form-select>

                    <b-card
                      class="shadow-sm rounded rounded-3 p-2 d-flex"
                      no-body
                    >
                      <div class="d-flex">
                        <div class="m-1">
                          <b-form-select
                            v-model="cs.matched"
                            :options="[
                              { text: '全部符合條件', value: 'AND' },
                              { text: '符合任何一個', value: 'OR' },
                            ]"
                            class="w-auto bg-primary text-white"
                          ></b-form-select>
                        </div>

                        <div
                          class="d-flex align-items-center flex-wrap flex-fill"
                        >
                          <div v-for="(c, jdx) in cs.conditions" :key="jdx">
                            <b-card
                              no-body
                              class="shadow-none rounded rounded-3 bg-light p-2 m-1 condition"
                            >
                              <div class="d-flex align-items-center">
                                <div class="mr-2">
                                  <b-form-select
                                    :options="conditionEnum"
                                    value-field="type"
                                    text-field="label"
                                    v-model="c.type"
                                    size="sm"
                                    @change="
                                      (type) => {
                                        changeCondition(idx, jdx, type);
                                      }
                                    "
                                    class="border-success"
                                  >
                                  </b-form-select>
                                </div>
                                <div v-if="c.operatorOptions" class="mr-2">
                                  <b-form-select
                                    :options="c.operatorOptions.map(o => ({text: o.text, value: o.value.operator}))"
                                    v-model="c.operator.operator"
                                    size="sm"
                                    @change="c.value = c.operatorOptions.find(o => o.value.operator == c.operator.operator).value.default"
                                    class="border-warning"
                                    v-if="isObject(c.operator)"
                                  >
                                  </b-form-select>
                                  <b-form-select
                                    :options="c.operatorOptions"
                                    v-model="c.operator"
                                    size="sm"
                                    @change="c.value = c.operator.default"
                                    class="border-warning"
                                    v-else
                                  >
                                  </b-form-select>
                                </div>
                                <div v-if="c.valueOptions" class="mr-2">
                                  <b-form-select
                                    :options="c.valueOptions"
                                    v-model="c.value"
                                    size="sm"
                                    class="border-warning"
                                  >
                                  </b-form-select>
                                </div>

                                <div v-if="c.operator" class="mr-2">
                                  <template
                                    v-if="
                                      ['age'].includes(c.type)
                                    "
                                  >
                                    <b-form-input
                                      type="number"
                                      min="1"
                                      pattern="[0-9]+"
                                      v-model="c.value"
                                      :placeholder="`請輸入${c.label}`"
                                    ></b-form-input>
                                  </template>
                                  <template
                                    v-if="
                                      ['include', 'exclude'].includes(c.operator)
                                    "
                                  >
                                    <b-form-input
                                      v-model="c.value"
                                      :placeholder="`請輸入${c.label}`"
                                    ></b-form-input>
                                  </template>
                                  <template
                                    v-if="
                                      [
                                        'birthday',
                                        'created_at',
                                        'registered_at',
                                      ].includes(c.type)
                                    "
                                  >
                                    <template
                                      v-if="
                                        ['lt', 'eq', 'gt'].includes(
                                          c.operator.operator
                                        )
                                      "
                                    >
                                      <datepicker
                                        v-model="c.value"
                                        bootstrap-styling
                                        format="yyyy-MM-dd"
                                        :language="zh"
                                        :disabled="isReadOnly"
                                      ></datepicker>
                                    </template>

                                    <template
                                      v-else-if="
                                       ['between'].includes(c.operator.operator) &&
                                       c.value.from
                                      "
                                    >
                                      <div class="d-flex align-items-center">
                                        <datepicker
                                          v-model="c.value.from"
                                          bootstrap-styling
                                          format="yyyy-MM-dd"
                                          :language="zh"
                                          :disabled="isReadOnly"
                                        ></datepicker>
                                        <div class="px-2">-</div>
                                        <datepicker
                                          v-model="c.value.to"
                                          bootstrap-styling
                                          format="yyyy-MM-dd"
                                          :language="zh"
                                          :disabled="isReadOnly"
                                          :disabled-dates="{ to: c.value.from }"
                                        ></datepicker>
                                      </div>
                                    </template>
                                    <template
                                      v-else-if="
                                        ['date-eq'].includes(c.operator.operator)
                                      "
                                    >
                                      <div class="d-flex">
                                        <b-form-select
                                          :options="monthOptions()"
                                          size="sm"
                                          v-model="c.value.month"
                                        >
                                        </b-form-select>

                                        <b-form-select
                                          :options="dayOptions(c.value.month)"
                                          size="sm"
                                          v-model="c.value.day"
                                        >
                                        </b-form-select>
                                      </div>
                                    </template>

                                    <template
                                      v-else-if="
                                        ['date-between'].includes(
                                          c.operator.operator
                                        ) &&
                                        c.value.from &&
                                        c.value.to
                                      "
                                    >
                                      <div class="d-flex align-items-center">
                                        <b-form-select
                                          :options="monthOptions()"
                                          size="sm"
                                          v-model="c.value.from.month"
                                          class="mr-2"
                                        >
                                        </b-form-select>

                                        <b-form-select
                                          :options="
                                            dayOptions(c.value.from.month)
                                          "
                                          size="sm"
                                          v-model="c.value.from.day"
                                        >
                                        </b-form-select>
                                        <div class="px-2">-</div>
                                        <b-form-select
                                          :options="monthOptions()"
                                          size="sm"
                                          v-model="c.value.to.month"
                                          class="mr-2"
                                        >
                                        </b-form-select>

                                        <b-form-select
                                          :options="dayOptions(c.value.to.month)"
                                          size="sm"
                                          v-model="c.value.to.day"
                                        >
                                        </b-form-select>
                                      </div>
                                    </template>
                                  </template>
                                </div>

                                <div>
                                  <b-button
                                    variant="danger"
                                    size="sm"
                                    class="px-2 py-1"
                                    pill
                                    @click="cs.conditions.splice(jdx, 1)"
                                    ><i
                                      class="fa fa-times mx-0"
                                      aria-hidden="true"
                                    ></i
                                  ></b-button>
                                </div>
                              </div>
                            </b-card>
                          </div>

                          <div style="flex-basis: 100%; height: 0;"></div>
                          <div>
                            <b-form-select
                              :options="conditionEnum"
                              value-field="type"
                              text-field="label"
                              @change="(type) => addCondition(cs, type)"
                              v-model="selectAddCondition"
                              class="m-1 border-success"
                            >
                              <template #first>
                                <b-form-select-option :value="null"
                                  >-- 增加條件 --</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </div>
                        </div>

                        <div v-if="idx != 0">
                          <b-button variant="link" class="text-dark"
                            @click="audience.conditionSet.conditions.splice(idx, 1)"
                            ><span
                              class="fa fa-2x fa-trash-o m-0"
                              aria-hidden="true"
                            ></span
                          ></b-button>
                        </div>
                      </div>
                    </b-card>
                  </div>

                  <br>
                  <b-form-select
                    :options="[
                      { text: '而且', value: 'AND' },
                      { text: '或', value: 'OR' },
                    ]"
                    :value="null"
                    v-model="selectAddConditionSet"
                    class="w-auto my-3"
                    @change="(setMatched) => addConditionSet(setMatched)"
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >-- 新增條件組合 --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
            
            </div>
            <div
              class="d-flex justify-content-center"
              style="margin-top: 100px"
            >
              <b-button v-if="!isReadOnly" @click="goListPage" class="mr-3" variant="outline-danger"
                >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
    <b-modal id="modal-upload" title="批次上傳">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group" v-if="audience.source_type == 'code'">
          <label class="flex-shrink-0 mr-2 mb-0">請選擇通路</label>
          <b-form-select 
            :options="nonLineMerchants"
            v-model="audience.source_merchant_id"
          ></b-form-select>
        </div>
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳 Excel 檔案</label>
          <b-form-file plain @change="upload"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          :href="`/excel/audience_${audience.source_type}.xlsx`"
          ><i class="fa fa-file-text"></i>下載範例檔案</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import _ from "lodash";
import moment from "moment";
import audienceApi from "@/apis/audience"
import merchantApi from "@/apis/merchant"
import fileMixin from "@/mixins/uploadFile";

const conditionEnum = [
  {
    type: "sexual",
    label: "性別",
    valueOptions: [
      { text: "男", value: 'male' },
      { text: "女", value: 'female' },
      { text: "未設定", value: null },
    ],
    value: 'male',
  },
  {
    type: "mobile_phone",
    label: "電話",
    valueOptions: [
      { text: "有資料", value: true },
      { text: "沒資料", value: false },
    ],
    value: true,
  },
  {
    type: "email",
    label: "郵件",
    valueOptions: [
      { text: "有資料", value: true },
      { text: "沒資料", value: false },
    ],
    value: true,
  },
  {
    type: "status",
    label: "帳號狀態",
    valueOptions: [
      { text: "未註冊", value: "unregistered" },
      { text: "已註冊", value: "registered" },
      { text: "已綁定", value: "binded" },
    ],
    value: "unregistered",
  },
  {
    type: "tag",
    label: "標籤",
    operatorOptions: [
      { text: "包含", value: "include" },
      { text: "不包含", value: "exclude" },
      { text: "有任何標籤", value: "any" },
      { text: "無任何標籤", value: "none" },
    ],
    operator: "include",
    value: "",
  },
  {
    type: "birthday",
    label: "生日",
    operatorOptions: [
      {
        text: "早於",
        value: {
          operator: "lt",
          default: new Date(),
        },
      },
      {
        text: "等於",
        value: {
          operator: "eq",
          default: new Date(),
        },
      },
      {
        text: "晚於",
        value: {
          operator: "gt",
          default: new Date(),
        },
      },
      {
        text: "區間",
        value: {
          operator: "between",
          default: {
            from: new Date(),
            to: new Date(),
          },
        },
      },
      {
        text: "日期等於",
        value: { operator: "date-eq", default: { month: 1, day: 1 } },
      },
      {
        text: "日期區間",
        value: {
          operator: "date-between",
          default: {
            from: { month: 1, day: 1 },
            to: { month: 1, day: 1 },
          },
        },
      },
    ],
    operator: {
      operator: "lt",
      default: new Date(),
    },
    value: new Date(),
  },
  {
    type: "age",
    label: "年齡",
    operatorOptions: [
      {
        text: "小於",
        value: {
          operator: "lt",
          default: 1,
        },
      },
      {
        text: "等於",
        value: {
          operator: "eq",
          default: 1,
        },
      },
      {
        text: "大於",
        value: {
          operator: "gt",
          default: 1,
        },
      },
    ],
    operator: {
      operator: "gt",
      default: 1,
    },
    value: 1,
  },
  {
    type: "created_at",
    label: "建立日期",
    operatorOptions: [
      {
        text: "早於",
        value: {
          operator: "lt",
          default: new Date(),
        },
      },
      {
        text: "等於",
        value: {
          operator: "eq",
          default: new Date(),
        },
      },
      {
        text: "晚於",
        value: {
          operator: "gt",
          default: new Date(),
        },
      },
      {
        text: "區間",
        value: {
          operator: "between",
          default: {
            from: new Date(),
            to: new Date(),
          },
        },
      },
    ],
    operator: {
      operator: "lt",
      default: new Date(),
    },
    value: new Date(),
  },
  {
    type: "registered_at",
    label: "註冊日期",
    operatorOptions: [
      {
        text: "早於",
        value: {
          operator: "lt",
          default: new Date(),
        },
      },
      {
        text: "等於",
        value: {
          operator: "eq",
          default: new Date(),
        },
      },
      {
        text: "晚於",
        value: {
          operator: "gt",
          default: new Date(),
        },
      },
      {
        text: "區間",
        value: {
          operator: "between",
          default: {
            from: new Date(),
            to: new Date(),
          },
        },
      },
    ],
    operator: {
      operator: "lt",
      default: new Date(),
    },
    value: new Date(),
  },
  {
    type: "merchant_id",
    label: "通路",
    valueOptions: [
    ],
    value: null,
  },
];

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Datepicker },
  mixins: [fileMixin],
  validations() {
    let validation = {
      audience: {
        title: { required },
      },
    };
    return validation;
  },

  data() {
    return {
      zh,
      showLoading: false,
      showLoadingUpload: false,
      isEditing: false,
      validationErrors: null,
      options: [
          { text: '條件過濾', value: 'filter' },
          { text: '手動匯入', value: 'import' },
      ], 
      nonLineMerchants: [],
      audience: {
        title: null,
        description: null,
        source: 'filter',
        source_type: null,
        source_merchant_id: null,
        file: null,
        config: {},
        conditionSet: {
          matched: 'AND',
          conditions:
          [
            {
              matched: "AND",
              // setMatched: null,
              conditions: [],
            },
          ]
        }
      },
      conditionEnum,
      selectAddCondition: null,
      selectAddConditionSet: null,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    isReadOnly() {
      return this.$route.name === "AudienceView"
    },
    isEdit() {
      return this.$route.name == "AudienceEdit"
    },
    normalizedConfig() {
      return {
        matched: this.audience.conditionSet.matched,
        conditions: this.audience.conditionSet.conditions.map(set => {
          return {
            matched: set.matched,
            conditions: set.conditions.map(c => {
              return {
                value: c.value,
                type: c.type,
                // operator 有可能是物件，將其簡化成一個字串 
                operator: ((c.operator && c.operator.operator) || c.operator) || 'eq',
              }
            })
          }
        })
      }
    }
  },
  async mounted() {
    await this.fetchMerchants()
    if (this.isEdit || this.isReadOnly) {
      try {
        let {data} = await audienceApi.getAudience(this.$route.params.audienceId)
        this.audience = {...this.audience, ...data.data}
        if (this.audience.config.conditions && this.audience.config.conditions[0].conditions.length > 0) {
          this.restoreCondition()
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  methods: {
    moment,
    isObject (object) {
      return _.isObject(object)
    },
    async upload(e) {
      try {
        this.showLoadingUpload = true
        this.audience.file = e.target.files[0];
      } catch(e) {
        console.error(e)
        this.$swal.fire({
          title: "上傳失敗",
          type: "error",
        });
      } finally {
        this.showLoadingUpload = false 
        this.$bvModal.hide("modal-upload");
      }
    },
    restoreCondition() {
      this.audience.conditionSet.matched = this.audience.config.matched
      this.audience.conditionSet.conditions = this.audience.config.conditions.map(cs => {
        return {
          matched: cs.matched,
          conditions: cs.conditions.map(c => {
            let condition = _.cloneDeep(this.conditionEnum.find((x) => x.type == c.type))
            if (_.isObject(condition.operator)) {
              condition.operator.operator = c.operator
            } else if (condition.operator) {
              condition.operator = c.operator
            }
            if (_.isObject(c.value)) {
              condition.value.from = moment(c.value.from).toDate()
              condition.value.to = moment(c.value.to).toDate()
            } else {
              condition.value = moment(c.value, 'YYYY-MM-DDTHH:mm:ss', true).isValid() ? moment(c.value).toDate() : c.value
            }
            return condition
          })
        }
      })
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list()
      let e = this.conditionEnum.find((x) => x.type == 'merchant_id')
      e.valueOptions = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ]
      this.nonLineMerchants = [
        { value: null, text: "請選擇" },
        ...data.data.filter(m => m.type != 'LINE').map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ]
    },
    goListPage() {
      this.$router.push({name: "AudienceList"});
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;
      this.audience.config = this.normalizedConfig
      try {
        if (!this.isEdit) {
          await audienceApi.createAudience(this.audience)
        } else {
          await audienceApi.updateAudience(this.$route.params.audienceId, this.audience)
        }
        this.$swal
          .fire({
            type: "success",
            text: this.isEdit ? "修改成功" : "新增成功",
          })
          .then(() => {
            this.goListPage()
          });
      } catch (e) {
        console.error(e)
        this.$swal
          .fire({
            type: "error",
            text: this.isEdit ? "更新失敗" : "新增失敗",
          })
          .then(() => {
            // this.goListPage()
          });
      }
    },
    addCondition(set, type) {
      set.conditions.push(
        _.cloneDeep(this.conditionEnum.find((x) => x.type == type))
      );
      this.selectAddCondition = null;
    },
    addConditionSet(setMatched) {
      this.audience.conditionSet.conditions.push({
        matched: "AND",
        setMatched: setMatched,
        conditions: [],
      });
      this.selectAddConditionSet = null;
    },
    changeCondition(idx, jdx, type) {
      let r = _.cloneDeep(this.conditionEnum.find((x) => x.type == type));

      this.audience.conditionSet.conditions[idx].conditions.splice(jdx, 1, r);
    },
    monthOptions() {
      return Array.from({ length: 12 }, (v, k) => k + 1).map((x) => {
        return { text: `${x}月`, value: x };
      });
    },
    dayOptions(month) {
      return Array.from(
        {
          length: new Date(new Date().getYear(), month, 0).getDate(),
        },
        (v, k) => k + 1
      ).map((x) => {
        return {
          text: `${x}日`,
          value: `${x}`,
        };
      });
    },
  },
};
</script>
<style lang="scss">
.condition {
  input.form-control {
    height: 31px;
  }
}
</style>
